import { useEffect, useState } from 'react'
import Table from '../components/Table';
import ImportButton from '../components/ImportButton';
import Button from '../components/Button';
import { ReactComponent as ImportIcon } from '../assets/ui-icons/12/import.svg';
import { ReactComponent as ConvertIcon } from '../assets/ui-icons/12/convert.svg';
import { ReactComponent as DataIcon } from '../assets/ui-icons/12/data.svg';
import TopDiv from '../components/ActionBar';
import TableDiv from '../components/TableDiv';
import Loading from '../components/Loading';
import config from '../config';
import "./styles/raw-page.css";

export default function RawPage() {
    const [tables, setTables] = useState([]);
    const [filesUpdated, setFilesUpdated] = useState(false);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [importProgress, setImportProgress] = useState(0);

    // list tables on setFileUpdated 
    useEffect(() => {
        const params = new URLSearchParams();
        params.set('statusList', JSON.stringify(["upload-complete"]));
        params.set('limit', "100");
        let target = config.API_BASE + '/tables/list';
        target += "?" + new URLSearchParams(params).toString();
        setIsTableLoading(true);
        fetch(target, { headers: { "x-owner-token": config['X_OWNER_TOKEN'] } })
            .then(async (res) => {
                if (res.status !== 200) {
                    throw new Error(await res.text());
                }
                const data = await res.json()
                setTables(data.map(t => ({ data: t, status: 0 })));
                setIsTableLoading(false);
            }).catch((error) => {
                console.error(error);
                setIsTableLoading(false);
            })
    }, [filesUpdated]);

    // on table select
    const onTableSelect = (tableId) => {
        setTables((prevTables) => {
            const newTables = [...prevTables];
            const foundTable = tables.find(t => t.data.tableId === tableId);
            foundTable.status = foundTable.status === 0 ? 1 : 0;
            return newTables;
        });
    }

    function convertOneTable(tableId, cb) {
        const endpoint = config.API_BASE + '/tables/convert';
        fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify({ "tableId": tableId }),
            headers: {
                "Content-Type": "application/json",
                "x-owner-token": config['X_OWNER_TOKEN']
            }
        })
            .then(async res => {
                if (res.status !== 200) {
                    throw new Error(await res.text());
                }
                cb();
            })
            .catch(error => {
                console.error(error);
                cb();
            });
    }

    // on raw table convert
    function onConvert() {
        setIsTableLoading(true);
        const selectedTables = tables.filter(t => t.status === 1);
        let index = selectedTables.length - 1;
        const convertCb = () => {
            index--;
            if (index < 0) {
                setIsTableLoading(false);
                setFilesUpdated(p => !p)
                return;
            }
            convertOneTable(selectedTables[index].data.tableId, convertCb);
        }
        convertOneTable(selectedTables[index].data.tableId, convertCb)
    }

    // on raw table import
    const onImport = (e) => {
        const file = e.target.files[0];
        if (!file.name.endsWith(".csv")) {
            return alert("Please select a valid csv file.");
        }

        e.target.value = "";

        const formData = new FormData();
        formData.append('file', file);
        formData.append('tableName', file.name);
        formData.append('encoding', "utf-8");

        const target = config.API_BASE + '/tables/upload';
        fetch(target, {
            method: 'POST',
            body: formData,
            headers: {
                "x-owner-token": config.X_OWNER_TOKEN
            }
        })
            .then(async res => {
                // check res status
                if (res.status !== 200) {
                    throw new Error(await res.text());
                }
                // start: ws demo
                const ws = new WebSocket(config.WS_URL);
                ws.onopen = async (e) => {
                    const resData = await res.json();
                    ws.send(JSON.stringify({
                        type: "join",
                        payload: {
                            savedTableId: resData.savedTableId
                        }
                    }))
                };
                ws.onmessage = (e) => {
                    const { progress, totalSize, finished } = JSON.parse(e.data);
                    finished && setImportProgress(0);
                    if (finished) {
                        setImportProgress(0)
                        setFilesUpdated((p) => !p);
                    } else {
                        setImportProgress(((progress / totalSize) * 100).toFixed(0));
                    }
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <>
            <TopDiv>
                <ImportButton name={'import'} icon={<ImportIcon />} onChange={onImport} progress={importProgress} />
                {
                    !!(tables.filter(t => t.status === 1).length) &&
                    <Button name={"convert"} icon={<ConvertIcon />} onclick={onConvert} theme={"box-shadow"} />
                }
            </TopDiv>
            <div className="main-content">
                <TableDiv>
                    {
                        isTableLoading ? <Loading /> :
                            tables.map((table, i) =>
                                <Table
                                    key={i}
                                    onClick={() => onTableSelect(table.data.tableId)}
                                    tableName={table.data.tableName}
                                    select={table.status === 1}
                                    icon={<DataIcon />}
                                />
                            )
                    }
                </TableDiv>
            </div>
        </>

    )
}
