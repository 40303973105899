import './styles/reset.css';
import './styles/global.css';
import './styles/text-style.css';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import MenuSection from './components/MenuSection';
import RawPage from './pages/RawPage';
import SmartPage from './pages/SmartPage';
import Keys from './components/Keys';
import PrimePage from './pages/PrimePage';
import PrimeLinkPage from './pages/PrimeLinkPage';
import SmartNewPage from './pages/SmartNewPage';
import MainMenu from './pages/MainMenu';
import Button from './components/Button';
import { ReactComponent as BackIcon } from "./assets/ui-icons/12/undo.svg"
import { useState } from 'react';
import Preview from './components/Preview';
import TestPage from './pages/TestPage';

function App() {

  const navigate = useNavigate();
  const showBackButton = ["/primes/link", "/smarts/edit", "/smarts/new"].includes(window.location.pathname);
  const [isMenuHidden, setIsMenuHidden] = useState(false);

  return (

    <div className="App" style={{ "--secondary-menu-width": isMenuHidden ? "64px" : "256px" }}>
      <div className='menu-primary'>
        <MainMenu />
      </div>
      <div className='top-bar'>
        {
          showBackButton &&
          <Button name={"back"} onclick={() => navigate(-1)} icon={<BackIcon />} theme={'btn'} />
        }
      </div>
      <MenuSection isHidden={isMenuHidden} onHide={() => setIsMenuHidden(p => !p)} />
      <Routes>
        <Route path='/' element={<RawPage />} />
        <Route path='/test' element={<TestPage />} />
        <Route path='/primes' element={<PrimePage />} />
        <Route path='/primes/link' element={<PrimeLinkPage />} />
        <Route path='/smarts' element={<SmartPage />} />
        <Route path='/smarts/edit' element={<SmartNewPage />} />
        <Route path='/smarts/new' element={<SmartNewPage />} />
        <Route path='/keys' element={<Keys />} />
        <Route path='/preview' element={<Preview />} />
      </Routes>
    </div>
  );
}

export default App;
