import { Fragment } from "react";
import "./styles/attach-table.css";
import ToggleButton from './ToggleButton'

export default function AttachTable({ options, handleToggleChange, label }) {
    return (
        <div className="hxp-comp-attach-table">
            <p className="label medium-14">{label}</p>
            <div className="container">
                {
                    options.map((option, index) => {
                        return (
                            <Fragment key={index}>
                                <div className="line meduim-16">
                                    {option.name}
                                    <ToggleButton toggle={option.status === 2} handleToggleChange={() => { handleToggleChange(option.tableId) }} />
                                </div>
                            </Fragment>
                        )
                    })
                }
            </div>
        </div>
    )
}
