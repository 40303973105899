import React, { Fragment } from 'react'
import ToggleButton from './ToggleButton'
import "./styles/common-keys.css";

export default function CommonKeys({ handleToggleChange, options, label }) {
    return (
        <div className='common-keys'>
            <p className='label medium-14'>{label}</p>
            <div className="container">
                {
                    options.map((option, index) => {
                        return (
                            <Fragment key={index}>
                                <div className="line" >
                                    <button className="key buttonKey meduim-14" value={option.keyValue}>{option.keyName && option.keyName}</button>
                                    <ToggleButton toggle={option.status === 1}
                                        handleToggleChange={() => {
                                            if (!(typeof handleToggleChange === "function")) {
                                                return;
                                            }
                                            handleToggleChange(option.keyValue);
                                        }} />
                                </div>
                            </Fragment>
                        )
                    }

                    )
                }
            </div>
        </div>
    )
}
