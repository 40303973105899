import { useEffect, useState } from 'react'
import config from '../config';
export default function Preview() {
    const setRecordsTable = useState([])[1];
    const setTableInfo = useState([])[1];

    useEffect(() => {
        const params = new URLSearchParams();
        params.set('tableId', localStorage.getItem("idTable"));
        params.set('limit', "10");
        let target = config.API_BASE + '/tables/read';
        target += "?" + new URLSearchParams(params).toString();
        fetch(target, { headers: { "x-owner-token": config.X_OWNER_TOKEN } })
            .then(async (res) => {
                if (res.status !== 200) {
                    throw new Error(await res.text());
                }
                return res.json()
            })
            .then(data => {
                setRecordsTable(data)
                console.log(data)

            })
            .catch((error) => {
                console.log(error)
            });
    }, [setRecordsTable]);

    useEffect(() => {
        const params = new URLSearchParams();
        params.set('tableId', localStorage.getItem("idTable"));
        let target = config.API_BASE + '/tables/info';
        target += "?" + new URLSearchParams(params).toString();
        fetch(target, { headers: { "x-owner-token": config.X_OWNER_TOKEN } })
            .then(async (res) => {
                if (res.status !== 200) {
                    throw new Error(await res.text());
                }
                return res.json()
            })
            .then(data => {
                setTableInfo(data.tableInfo.schema)
            })
            .catch((error) => {
                console.log(error)
            });
    }, [setTableInfo]);

    return (
        <div>
        </div>
    )
}
