import "./styles/toggle-button.css"

export default function ToggleButton({toggle, handleToggleChange}) {
    
    return (
        <div className={toggle?'toggle-container':"toggle-container disabledButton"} onClick={handleToggleChange}>
            <div className= {toggle? "toggle-switch disable " : "toggle-btn-off toggle-switch "}></div>
            <div className={toggle? "toggle-btn-on toggle-switch " : "toggle-switch disable"}></div>
        </div>
    )
}