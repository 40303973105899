import "./styles/prime-link-page.css";
import DropDown from '../components/DropDown'
import { useEffect, useState } from "react";
import { ReactComponent as ConfirmIcon } from '../assets/ui-icons/12/confirm.svg';
import KeyInput from "../components/KeyInput";
import TableInfos from "../components/TableInfos"
import config from "../config";
import Button from "../components/Button";
export default function EditKeys() {
    const [tables1, setTables1] = useState([]);
    const [tables2, setTables2] = useState([]);
    const [tags1, setTags1] = useState([]);
    const [selectedTag1, setSelectedTag1] = useState("");
    const [areTagsLoading, setAreTagsLoading] = useState(true);
    const [tags2, setTags2] = useState([]);
    const [selectedTag2, setSelectedTag2] = useState("");
    const [actionName, setActionName] = useState("")
    const [tagsUpdated, setTagsUpdated] = useState(false);
    const [newKey, setNewKey] = useState("");
    const [selectedTable2, setSelectedTable2] = useState("");
    const [idPrimeTable, setIdPrimeTable] = useState(localStorage.idTable);
    localStorage.removeItem("idTable");

    const actions = [
        {
            value: "link-old",
            name: "Link to Old Key"
        },
        {
            value: "link-new",
            name: "Link to New Key"
        },
        {
            value: "unlink",
            name: "Unlink Tag"
        }
    ]
    function displayTables(setTable) {
        const params = new URLSearchParams();
        params.set('statusList', JSON.stringify(["convert-complete"]));
        params.set('limit', "100");
        let target = config.API_BASE + '/tables/list';
        target += "?" + new URLSearchParams(params).toString();
        fetch(target, {
            headers: {
                "x-owner-token": config.X_OWNER_TOKEN
            }
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setTable(data);
            });
    }
    useEffect(() => {
        displayTables(setTables1)
    }, []);
    useEffect(() => {
        displayTables(setTables2)
    }, []);
    const fetchTableInfo = (tableId, index) => {
        if (tableId === "") return;
        setAreTagsLoading(true);
        const params = new URLSearchParams();
        params.set('tableId', tableId);
        let target = config.API_BASE + '/tables/info';
        target += "?" + new URLSearchParams(params).toString();
        fetch(target, {
            headers: {
                "x-owner-token": config.X_OWNER_TOKEN
            }
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                switch (index) {
                    case 0:
                        setTimeout(() => {
                            setTags1(data.tableInfo.schema);
                            setAreTagsLoading(false);
                        }, 1000)
                        break;
                    case 1:
                        setTimeout(() => {
                            setTags2(data.tableInfo.schema);
                            setAreTagsLoading(false);
                        }, 1000)
                        break;
                    default:
                        break;
                }
            });
    }
    useEffect(() => {
        fetchTableInfo(idPrimeTable, 0)
    }, [idPrimeTable, tagsUpdated]);

    useEffect(() => {
        fetchTableInfo(selectedTable2, 1)
    }, [selectedTable2]);

    const handleChange = (e) => {
        setActionName(e.target.value);
    }

    function confirmOnClick() {
        switch (actionName) {
            case "link-new":
                const endpoint1 = config.API_BASE + '/labels/link';
                fetch(endpoint1, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        "x-owner-token": config.X_OWNER_TOKEN
                    },
                    body: JSON.stringify({ 'labelId': selectedTag1, 'newKeyName': newKey }),
                })
                    .then(async (res) => {
                        if (res.status !== 200) {
                            throw new Error(await res.text())
                        }
                        return res.text();
                    }).then(message => {
                        console.log(message);
                        setTagsUpdated((p) => !p);
                        setActionName("");
                        setNewKey("");
                    }).catch(error => {
                        console.error(error);
                    });
                break;
            case "link-old":
                const endpoint2 = config.API_BASE + '/labels/link';
                fetch(endpoint2, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        "x-owner-token": config.X_OWNER_TOKEN
                    },
                    body: JSON.stringify({ 'labelId': selectedTag1, 'oldKeyId': tags2.find(tag => tag.labelId === selectedTag2).keyId }),
                })
                    .then(async (res) => {
                        if (res.status !== 200) {
                            throw new Error(await res.text())
                        }
                        return res.text();
                    }).then(message => {
                        console.log(message);
                        setTagsUpdated((p) => !p);
                        setActionName("");
                        setTags2([])
                        setSelectedTag2("")
                        setSelectedTable2("")
                    }).catch(error => {
                        console.error(error);
                    });

                break;
            case "unlink":
                const endpoint3 = config.API_BASE + '/labels/unlink';
                fetch(endpoint3, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        "x-owner-token": config.X_OWNER_TOKEN
                    },
                    body: JSON.stringify({ 'labelId': selectedTag1 }),
                })
                    .then(async res => {
                        if (res.status !== 200) {
                            throw new Error(await res.text())
                        }
                        return res.text();
                    }).then(message => {
                        console.log(message)
                        setTagsUpdated((p) => !p);
                        setActionName("");
                    }).catch(error => {
                        console.error(error);
                    });
                break;
            default:
                break;
        }

    }
    return (
        <>
            <div className='hxp-comp-action-bar'>
                <div className='dropdown-container' style={{ display: "flex", gap: "8px" }}>
                    {
                        !!(tables1.length) &&
                        <div>
                            <DropDown
                                label="1st table"
                                options={tables1.map((table, index) => ({ title: table.tableName, key: index, value: table.tableId }))}
                                value={idPrimeTable}
                                onChange={(e) => { setIdPrimeTable(e.target.value) }}
                            />
                        </div>
                    }
                    {
                        !!(tags1.length) && !(idPrimeTable === "") &&
                        <div>
                            <DropDown
                                label='1st tag'
                                disabled={areTagsLoading}
                                options={[{ title: "Choose Tag", value: "" }].concat(tags1.map(label => ({ title: label.labelName.substring(2), value: label.labelId })))}
                                value={selectedTag1}
                                onChange={(e) => { setSelectedTag1(e.target.value) }}
                            />
                        </div>
                    }
                    {
                        !(idPrimeTable === "") && !(selectedTag1 === "") && (actionName === "link-new") &&
                        <div>
                            <KeyInput
                                label="type new key"
                                value={newKey} handleChange={(id) => setNewKey(id)} theme={"big-input"} placeholder={"type new key"} />
                        </div>
                    }
                    {
                        !!(tables2.length) && !(idPrimeTable === "") && !(selectedTag1 === "") && (actionName === "link-old") &&
                        <div>
                            <DropDown
                                label="2nd table"
                                options={[{ title: "Choose Table", value: "" }].concat(tables2.filter((t) => (t.tableId !== idPrimeTable)).map((table, index) => ({ title: table.tableName, key: index, value: table.tableId })))}
                                onChange={(e) => setSelectedTable2(e.target.value)}
                                value={selectedTable2}
                            />
                        </div>
                    }
                    {
                        !!(tags2.length) && !(selectedTable2 === "") && (actionName === "link-old") &&
                        <div>
                            <DropDown
                                label="2nd tag"
                                disabled={areTagsLoading}
                                options={[{ title: "Choose Tag", value: "" }].concat(tags2.map(label => ({ title: label.labelName.substring(2), value: label.labelId, keyId: label.keyId })))}
                                value={selectedTag2}
                                onChange={(e) => { setSelectedTag2(e.target.value) }}
                            />
                        </div>
                    }

                </div>
                <div className="actions-selector" style={{ marginLeft: "auto", display: "flex", gap: "8px" }}>
                    {
                        (
                            (selectedTag2 && selectedTag1) || (actionName === "unlink") || (selectedTag1 && newKey)
                        ) &&
                        <Button onclick={confirmOnClick} icon={<ConfirmIcon />} name={"Confirm"} theme="box-shadow" />
                    }
                    {
                        !(idPrimeTable === "") && !(selectedTag1 === "") &&
                        <div>
                            <DropDown
                                label="action"
                                options={[{ title: "Choose Action", value: "" }].concat(actions.map((action, index) => ({ title: action.name, key: index, value: action.value })))}
                                onChange={handleChange}
                                value={actionName}
                            />
                        </div>
                    }
                </div>
            </div>
            <div className="main-content" style={{ display: "flex", gap: "8px" }}>
                {
                    !!(tags1.length) && !(idPrimeTable === "") &&
                    <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                        <p className="meduim-14" >1st tagline</p>
                        <TableInfos
                            disabled={areTagsLoading}
                            options={tags1.map(tag => ({ tag: tag.labelName.substring(2), key: tag.keyName || "no key", tagValue: tag.labelId, keyValue: tag.keyId, }))}
                            selectedTag={selectedTag1}
                        />
                    </div>
                }
                {
                    !!(tags2.length) && !(selectedTable2 === "") && (actionName === "link-old") &&
                    <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                        <p className="meduim-14">2st tagline</p>
                        <TableInfos
                            disabled={areTagsLoading}
                            options={tags2.map(tag => ({ tag: tag.labelName.substring(tag.labelName.indexOf("-")), key: tag.keyName || "no key", tagValue: tag.labelId, keyValue: tag.keyId }))}
                            selectedTag={selectedTag2}
                        />
                    </div>
                }
            </div>
        </>
    )
}
