import React, { Children } from 'react'
import "./styles/table-div.css";
export default function TableDiv({children}) {
    return (
        <div className="content-div">
            <div className='table-div'>
                {Children.map(children, child => <>{child}</>
                )}
            </div>

        </div>
    )
}
