import React, { useEffect, useState } from 'react'
import Button from "../components/Button";
import "./styles/smart-page.css";
import { ReactComponent as EditIcon } from '../assets/ui-icons/12/edit.svg';
import { ReactComponent as RenameIcon } from '../assets/ui-icons/12/rename.svg';
import { ReactComponent as SmartTableIcon } from '../assets/ui-icons/12/smart-table.svg';
import { ReactComponent as EyeIcon } from '../assets/ui-icons/12/eye.svg';

import Table from '../components/Table';
import TopDiv from '../components/ActionBar';
import TableDiv from '../components/TableDiv';
import Loading from '../components/Loading';
import config from '../config';
import { ReactComponent as ConfirmIcon } from '../assets/ui-icons/12/confirm.svg';
import KeyInput from '../components/KeyInput';

export default function SmartPage() {
    const [smartTables, setSmartTables] = useState([]);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [showInput, setShowInput] = useState(false)
    const [newSmartTableName, setNewSmartTableName] = useState("");
    const [refresh, setRefresh] = useState(false)


    useEffect(() => {
        const params = new URLSearchParams();
        params.set('statusList', JSON.stringify(["active"]));
        params.set('limit', "100");
        let target = config.API_BASE + '/smart-tables/list';
        target += "?" + new URLSearchParams(params).toString();
        setIsTableLoading(true);
        fetch(target, {
            headers: {
                "x-owner-token": config.X_OWNER_TOKEN
            }
        })
            .then(async (res) => {
                if (res.status !== 200) {
                    throw new Error(await res.text());
                }
                const data = await res.json();
                setSmartTables(data.map(s => ({ status: 0, data: s })));
                setIsTableLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsTableLoading(false);
            });
    }, [refresh]);

    const onSmartSelect = (index) => {
        setShowInput(false);
        console.log("selected", index)
        if (smartTables.filter(s => s.status === 1).length === 0) {
            localStorage.idSmartTable = smartTables[index].data._id;
        }
        setSmartTables((prevSmarts) => {
            const newSmarts = [...prevSmarts]
            newSmarts[index].status = newSmarts[index].status === 0 ? 1 : 0;
            return newSmarts;
        })
    }
    function Rename() {
        const target = config.API_BASE + '/smart-tables/rename';
        const idSmartTable = localStorage.getItem("idSmartTable");
        fetch(target, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "x-owner-token": config.X_OWNER_TOKEN
            },
            body: JSON.stringify({ "smartTableId": idSmartTable, "newSmartTableName": newSmartTableName }),

        }).then(response => response).then(data => {
            setShowInput(false)
            setRefresh(p => !p)
            setNewSmartTableName("")
        })
            .catch(error => {
                console.error(error);
                return (
                    alert('Error updating name table ')
                );
            });
    }
    return (
        <>
            <TopDiv>
                {
                    smartTables.filter((s) => s.status === 1).length === 1 && !showInput &&
                    <>
                        <Button name={"rename"} icon={<RenameIcon />} onclick={() => setShowInput(true)} />
                        <Button name={"see source primes"} icon={<EyeIcon />} href={"primes"} />
                        <Button name={"edit smart"} icon={<EditIcon />} href={"smarts/edit"} theme={"box-shadow"} />
                    </>
                }
                {
                    !smartTables.filter((s) => s.status === 1).length && !showInput &&
                    <Button name={"new smart"} icon={<SmartTableIcon />} href={"smarts/new"} theme={"box-shadow"} />
                }

                {
                    smartTables.filter((s) => s.status === 1).length === 1 && showInput &&
                    <>
                        <KeyInput label={"new smart table name"} placeholder={"type new smart table name"} value={newSmartTableName} handleChange={(name) => setNewSmartTableName(name)} theme={"small-input"} />
                        <Button name={"confirm"} onclick={Rename} icon={<ConfirmIcon />} />
                    </>
                }
            </TopDiv>
            <div className="main-content">
                <TableDiv>
                    {isTableLoading && <Loading />}
                    {
                        !isTableLoading &&
                        smartTables.map((smartTable, index) =>
                            <Table
                                onClick={() => onSmartSelect(index)}
                                key={index}
                                tableName={smartTable.data.content.name}
                                select={smartTable.status === 1}
                                icon={<SmartTableIcon />}
                            />)
                    }
                </TableDiv>
            </div>
        </>
    )
}
