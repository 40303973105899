import "./styles/main-menu.css";
import IconMenu from '../components/IconMenu'

export default function PrincipalMenu() {
  return (
    <div className="principal-menu">
      <div>
        <IconMenu srcImg={"/images/hexagonePink.png"} />
        <IconMenu srcImg={"/images/hexagoneDark.png"} />
        <IconMenu srcImg={"/images/hexagone.png"} />
        <IconMenu srcImg={"/images/hexagone.png"} />
        <IconMenu srcImg={"/images/hexagone.png"} />
      </div>
      <div className="bottom-div">
        <IconMenu srcImg={"/images/hexagone.png"} />
        <IconMenu srcImg={"/images/hexagone.png"} />
        <IconMenu srcImg={"/images/hexagone.png"} />
      </div>
    </div>
  )
}
