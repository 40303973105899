
import { ReactComponent as AddIcon } from "../assets/ui-icons/12/add.svg"

function TestPage() {
    return (
        <div className="main-content">
            <div style={{ stroke: "var(--pink-20)" }}>
                <AddIcon />
            </div>
        </div>
    )
}

export default TestPage