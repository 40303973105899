import React from 'react'
import "./styles/table.css"
export default function Table({ tableName, select, onClick, icon }) {
  const classList = ["prime-table meduim-16"]
  select && classList.push("select")
  return (
    <div onClick={onClick} className={classList.join(" ")}>
      <div className="icon">{icon}</div>
      <div className="label">{tableName}</div>
    </div>
  )
}
