import { useRef } from "react";
import Button from "./Button";
import "./styles/import-button.css"

export default function ImportButton({ icon, name, onChange, progress }) {

  const inputRef = useRef();
  const inputUUID = crypto.randomUUID();

  return (
    <>
      <div style={{display: "none"}}>
        <label htmlFor={inputUUID}>{name}</label>
        <input id={inputUUID} ref={inputRef} type="file" accept='.csv' onChange={onChange} />
      </div>
      <Button name={name} icon={icon} theme="btn" onclick={() => {inputRef.current.click()}} progress={progress} />
    </>
  )
}
