import React, { useEffect, useRef } from "react";
import "./styles/button.css";

export default function Button({ name, icon, onclick, href, theme = "", progress = 0 }) {
  const classList = ["hxp-comp-button", "meduim-16"]
  theme && classList.push("variation-" + theme);
  const gradientProperty = `linear-gradient(90deg, var(--pink-60) 0%, var(--pink-60) ${progress}%, transparent ${progress}%, transparent 100%);`
  const buttonRef = useRef();
  useEffect(() => {
    buttonRef.current.setAttribute("style", `--progress-gradient: ${gradientProperty}`)
  }, [gradientProperty])

  return (
    <>
      {
        href &&
        <a className={classList.join(" ")} href={href} >
          <div className="button" ref={buttonRef}>
            <div className="icon">{icon}</div>
            <div className="label">{name}</div>
          </div>
        </a>
      }
      {
        !href &&
        <button className={classList.join(" ")} onClick={onclick}>
          <div className="button" ref={buttonRef}>
            <div className="icon">{icon}</div>
            <div className="label">{name}</div>
          </div>
        </button>
      }
    </>
  )
}
