import "./styles/key-input.css";

export default function KeyInput({ handleChange, value, theme, placeholder, disabled, label }) {
  const classList = ["key-input", theme, "meduim-16"]
  const inputUUID = crypto.randomUUID();
  return (
    <div className="input-container">
      <label htmlFor={inputUUID} hidden>{label}</label>
      <input className={classList.join(' ')}
        type="text"
        id={inputUUID}
        placeholder={placeholder}
        value={value}
        onChange={(e) => { handleChange(e.target.value) }} disabled={disabled} />
    </div>
  )
}