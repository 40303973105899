import React from 'react'
import Menu from './Menu'
import "./styles/menu-section.css";
import { ReactComponent as RawIcon } from "../assets/ui-icons/12/data.svg"
import { ReactComponent as TableIcon } from "../assets/ui-icons/12/table.svg"
import { ReactComponent as SmartTableIcon } from "../assets/ui-icons/12/smart-table.svg"

export default function MenuSection({ onHide, isHidden }) {
    const classList = ['menu-secondary'];
    isHidden && classList.push("hidden");

    const menus = [
        {
            link: "/",
            name: "Raw ",
            icon: <RawIcon />,
            icon1: <RawIcon />
        },
        {
            link: "/primes",
            name: "Prime ",
            icon: <TableIcon />,
            icon1: <TableIcon />
        },
        {
            link: "/smarts",
            name: "Smart ",
            icon: <SmartTableIcon />,
            icon1: <SmartTableIcon />
        }
    ]

    return (
        <nav className={classList.join(" ")}>
            {
                menus.map((menu, i) => {
                    return (
                        <Menu
                            key={i}
                            href={menu.link}
                            name={menu.name}
                            icon={menu.icon}
                            icon1={menu.icon1}
                            isCollapsed={isHidden}
                        />
                    )
                })
            }
            <div className="hide-bar" onClick={onHide}></div>
        </nav>
    )
}
