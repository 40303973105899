import "./styles/table-infos.css";

export default function TableInfos({ options, selectedTag, disabled, onClick }) {
    return (
        <div className='table'>
            {
                options.map((option, index) => {
                    const classList = ["line-table"];
                    selectedTag === option.tagValue && classList.push("selected-label");
                    disabled && classList.push("disabled")
                    return (
                        <div key={index}
                            className={classList.join(" ")}
                            onClick={() => {
                                if (!(typeof onClick === "function")) {
                                    return;
                                }
                                onClick(option.keyValue);
                            }}
                        >
                            <div value={option.tagValue} ><p className="meduim-16">{option.tag}</p></div>
                            <div><button className={(option.keyValue ? "key buttonKey" : "key emptyBtn")} value={option.keyValue}>{option.key}</button></div>
                        </div>
                    )
                }
                )}
        </div>
    )
}
