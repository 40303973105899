import { useEffect, useState } from 'react'
import './styles/prime-page.css';
import Table from '../components/Table';
import TopDiv from '../components/ActionBar';
import TableDiv from '../components/TableDiv';
import Loading from '../components/Loading';
import Button from '../components/Button';
import config from '../config';
import KeyInput from '../components/KeyInput';
import { ReactComponent as RenameIcon } from '../assets/ui-icons/12/rename.svg';
import { ReactComponent as SmartTableIcon } from '../assets/ui-icons/12/smart-table.svg';
import { ReactComponent as ConfirmIcon } from '../assets/ui-icons/12/confirm.svg';
import { ReactComponent as LinkIcon } from '../assets/ui-icons/12/link.svg';
import { ReactComponent as TableIcon } from '../assets/ui-icons/12/table.svg';
// import { ReactComponent as EyeIcon } from '../assets/ui-icons/12/eye.svg';

export default function PrimePage() {
    const [tables, setTables] = useState([]);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [showRenameInput, setShowRenameInput] = useState(false)
    const [newTableName, setNewTableName] = useState("");
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        const selectedTables = tables?.filter(t => t.status === 1)
        if (selectedTables.length === 1) {
            localStorage.setItem("idTable", selectedTables[0].data.tableId)
        }
    }, [tables])

    useEffect(() => {
        const params = new URLSearchParams();
        params.set('statusList', JSON.stringify(["convert-complete"]));
        params.set('limit', "100");
        let target = config.API_BASE + '/tables/list';
        target += "?" + new URLSearchParams(params).toString();
        setIsTableLoading(true);
        fetch(target, { headers: { "x-owner-token": config.X_OWNER_TOKEN } })
            .then(async (res) => {
                if (res.status !== 200) {
                    throw new Error(await res.text());
                }
                return res.json()
            })
            .then(data => {
                setTables(data.map(d => ({ data: d, status: 0 })));
                setIsTableLoading(false);
            })
            .catch((error) => {
                console.log(error)
                setIsTableLoading(false);
            });
    }, [refresh]);

    const onTableSelect = (e, index) => {

        setShowRenameInput(false);
        // If Shift key is pressed
        if ((e.shiftKey || e.metaKey)) {
            const firstSelectedIndex = tables.findIndex(t => t.status === 1);
            const minIndex = Math.min(index, firstSelectedIndex);
            const maxIndex = Math.max(index, firstSelectedIndex);
            setTables((prevTables) => {
                let newTables = [...prevTables];
                newTables = newTables.map((t, i) => ({ ...t, status: i > minIndex - 1 && i < maxIndex + 1 ? 1 : 0 }));
                return newTables;
            })
        }
        // If Ctrl key is pressed
        else if (e.ctrlKey) {
            setTables((prevTables) => {
                let newTables = [...prevTables];
                newTables = newTables.map((t, i) => ({ ...t, status: i === index || t.status === 1 ? 1 : 0 }));
                return newTables;
            })
        }
        else {
            setTables((prevTables) => {
                let newTables = [...prevTables];
                const selectedTableStatus = newTables[index].status;
                newTables = newTables.map(t => ({ ...t, status: 0 }));
                newTables[index].status = selectedTableStatus === 1 ? 0 : 1;
                return newTables;
            })
        }
    }
    function Rename() {
        const target = config.API_BASE + '/tables/rename';
        const IdPrimeTable = localStorage.getItem("idTable");
        fetch(target, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "x-owner-token": config.X_OWNER_TOKEN
            },
            body: JSON.stringify({ "tableId": IdPrimeTable, "newTableName": newTableName }),

        }).then(response => response).then(data => {
            setShowRenameInput(false)
            setRefresh(p => !p)
            setNewTableName("")
        })
            .catch(error => {
                console.error(error);
                return (
                    alert('Error updating name table ')
                );
            });
    }
    return (
        <>
            <TopDiv>
                {/* <Button name={"open"} icon={<ImportContactsOutlinedIcon />} /> */}
                {
                    !!(tables?.filter(t => t.status === 1).length === 1) && !showRenameInput &&
                    <>
                        <Button name={"rename"} icon={<RenameIcon />} onclick={() => setShowRenameInput(true)} />
                        <Button name={"link tagline"} icon={<LinkIcon />} href={'primes/link'} theme={"box-shadow"} />
                        {/* <Button name={"Preview table"} icon={<EyeIcon />} href={'preview'} theme={"box-shadow"} /> */}
                    </>
                }
                {/* <Button name={"see sample"} icon={<RemoveRedEyeOutlinedIcon />} /> */}
                {/* <Button name={"see tagline"} icon={<RemoveRedEyeOutlinedIcon />} /> */}
                {
                    !!(tables?.filter(t => t.status === 1).length > 0) && !showRenameInput &&
                    <Button name={"new smart"} icon={<SmartTableIcon />} href={"smarts/new"} theme={"box-shadow"} />
                }
                {
                    !!(tables?.filter(t => t.status === 1).length === 1) && showRenameInput &&
                    <>
                        <KeyInput label={"new table name"} placeholder={"type new table name"} value={newTableName} handleChange={(name) => setNewTableName(name)} theme={"small-input"} />
                        <Button name={"confirm"} onclick={Rename} icon={<ConfirmIcon />} />
                    </>
                }
            </TopDiv>
            <div className="main-content">
                <TableDiv>
                    {isTableLoading && <Loading />}
                    {
                        !isTableLoading &&
                        tables?.map((table, index) => {
                            return (
                                <Table
                                    key={index}
                                    icon={<TableIcon />}
                                    tableName={table.data.tableName}
                                    select={table.status === 1}
                                    onClick={(e) => onTableSelect(e, index)}
                                />
                            )
                        })
                    }
                </TableDiv>
            </div>
        </>
    )
}
