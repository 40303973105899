import "./styles/keys.css"
import Buttons from './Button'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { useEffect, useState } from "react";
import NewKeys from "./NewKeys";
import config from "../config";

export default function Keys() {
    const [keys, setKeys]= useState([]);
    useEffect(()=>{
        const params = new URLSearchParams();
        params.set('statusList', JSON.stringify(["active"]));
        params.set('limit', "100");
        let target = config.API_BASE + '/keys/list';
        target += "?" + new URLSearchParams(params).toString();
        fetch(target)
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            setKeys(data);
        });
    },[])
    return (
        <div className="keys-container">
            <div className='top-div'>
                <Buttons name={"edit"} icon={<ModeEditOutlineOutlinedIcon />} />
                <Buttons name={"new"} icon={<AddBoxOutlinedIcon />} />
            </div>
            <div className='keys-body'>
            {
                    <NewKeys options={keys.map(key => ({value: key.keyId, name: key.keyName}))}  />
            }
            </div>
        </div>
    )
}
