import "./styles/smart-new-page.css";
import {ReactComponent as SaveIcon} from '../assets/ui-icons/12/floppy-disk.svg';
import {ReactComponent as ImportIcon} from '../assets/ui-icons/12/import.svg';
import Buttons from "../components/Button";
import DropDown from "../components/DropDown";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import AttachTable from "../components/AttachTable";
import CommonKeys from "../components/CommonKeys";
import KeyInput from "../components/KeyInput";
import config from "../config";

export default function NewSmartTable() {
    const [tables, setTables] = useState([])
    const [selectedTable, setSelectedTable] = useState("")
    const [smartTableName, setSmartTableName] = useState("");
    const [refrech, setRefrech] = useState(false);
    const [editData, setEditData] = useState(null);
    const idSmartTable = useState(localStorage.idSmartTable)[0];
    localStorage.removeItem("idSmartTable");
    const navigate = useNavigate();

    // get smart table info
    const fetchSmartInfo = useCallback(() => {
        if (window.location.pathname !== "/smarts/edit") {
            return;
        } else if (!idSmartTable) {
            navigate(-1)
        }
        const params = new URLSearchParams();
        params.set('smartTableId', idSmartTable);
        let target = config.API_BASE + '/smart-tables/info';
        target += "?" + new URLSearchParams(params).toString();
        fetch(target, {
            headers: {
                "x-owner-token": config.X_OWNER_TOKEN
            }
        })
            .then(async (res) => {
                if (res.status !== 200) {
                    throw new Error(await res.text());
                }
                return res.json();
            })
            .then((data) => {
                setEditData(data);
                setSmartTableName(data.smartTableName)
            }).catch((error) => {
                console.log(error)
            })
    }, [idSmartTable, navigate])
    useEffect(() => {
        fetchSmartInfo()
    }, [fetchSmartInfo])

    // list convert-complete tables
    useEffect(() => {
        if (window.location.pathname === "/smarts/edit" && !editData) {
            return;
        }
        console.log("editData", editData)
        const params = new URLSearchParams();
        params.set('statusList', JSON.stringify(["convert-complete"]));
        params.set('limit', "100");
        let target = config.API_BASE + '/tables/list';
        target += "?" + new URLSearchParams(params).toString();
        fetch(target, {
            headers: {
                "x-owner-token": config.X_OWNER_TOKEN
            }
        })
            .then(async (res) => {
                if (res.status !== 200) {
                    throw new Error(await res.text())
                }
                return res.json();
            }).then((data) => {
                setTables(
                    data.map((d) => {
                        const srcTable = editData?.sourceTables.find(t => t.tableId === d.tableId);
                        return { status: srcTable ? (srcTable.isActive ? 2 : 1) : 0, data: { ...d, keys: d.keys.map(k => ({ ...k, status: editData?.keys.find(editKey => editKey.keyId === k.keyId) ? 1 : 0 })) } }
                    })
                );
            })
            .catch((error) => {
                console.log(error);
            });
    }, [refrech, editData])

    const onTableAttach = () => {
        setSelectedTable("");
        setTables(prevTables => {
            const newTables = [...prevTables];
            newTables.find(t => t.data.tableId === selectedTable).status = 1;
            return newTables;
        });
    }

    const onTableToggle = (tableId) => {
        setTables((prevTables) => {
            const newTables = [...prevTables];
            const foundTable = newTables.find(t => t.data.tableId === tableId);
            foundTable.status = foundTable.status === 1 ? 2 : 1;
            return newTables;
        })
    }

    const onKeyToggle = (keyId) => {
        setTables((prevTables) => {
            let newTables = [...prevTables];
            newTables = newTables.map(t => ({
                ...t,
                data: {
                    ...(t.data),
                    keys: t.data.keys.map(k => {
                        const status = k.keyId === keyId ? (k.status === 0 ? 1 : 0) : k.status;
                        return { ...k, status };
                    })
                }
            }))
            return newTables;
        })
    }

    const commonKeysFromTables = (tables) => {
        return tables
            .filter(t => t.status === 2) // get only toggled tables (status 2)
            .map(t => t.data.keys) // an 2D array of each table's keys array
            .reduce((all, keysArray) => [...all, ...keysArray], []) // all keys spread in one 1D array
            .reduce((all, key) => all.find(k => k.keyId === key.keyId) ? all : [...all, key], []) // remove duplicates
            .filter(key => tables.filter(t => t.status === 2).every(t => t.data.keys.find(k => k.keyId === key.keyId))) // leave common keys only
    }


    const onSmartCreate = (isUpdate) => {
        const target = config.API_BASE + '/smart-tables/create';
        const target2 = config.API_BASE + '/smart-tables/source-tables/attach'
        const newSmartTable = {
            "smartTableName": smartTableName,
            'keysList':
                commonKeysFromTables(tables)
                    .filter(k => k.status === 1)
                    .map(k => k.keyId),
        }
        if (isUpdate) {
            newSmartTable.isUpdate = true;
            newSmartTable.smartTableId = editData.smartTableId
        }
        fetch(target, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "x-owner-token": config.X_OWNER_TOKEN
            },
            body: JSON.stringify(newSmartTable),
        })
            .then(async (res) => {
                if (res.status !== 200) {
                    throw new Error(await res.text());
                }
                return res.json()
            })
            .then(data => {
                const smartTableId = data.smartTableId
                console.log('SmartTable created succussfully. smartTableId: %s', smartTableId);
                const sourceTables = tables.filter(t => t.status > 0).map((t, i) => ({ isActive: t.status === 2, tableId: t.data.tableId }));
                fetch(target2, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        "x-owner-token": config.X_OWNER_TOKEN
                    },
                    body: JSON.stringify({ "smartTableId": smartTableId, "sourceTables": sourceTables })
                })
                    .then(async (res) => {
                        if (res.status !== 200) {
                            throw new Error(await res.text());
                        }
                        return res.text()
                    })
                    .then(message => {
                        console.log(message);
                        setSelectedTable("")
                        setRefrech(r => !r)
                        setSmartTableName("")
                    })
                    .catch(error => console.log(error))
            }).catch((error) => console.log(error))
    }

    return (
        <>
            <div className='hxp-comp-action-bar' style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", gap: "8px" }}>
                    <DropDown
                        options={[{ title: "Choose Tables", value: "" }].concat(tables.filter(t => t.status === 0).map(table => ({ title: table.data.tableName, value: table.data.tableId })))}
                        value={selectedTable}
                        onChange={(e) => { setSelectedTable(e.target.value) }}
                    />
                    {
                        selectedTable &&
                        <Buttons name={"attach"} icon={<ImportIcon />} onclick={onTableAttach} />
                    }
                </div>
                <div style={{ display: "flex", gap: "8px" }}>
                    {
                        <KeyInput label={"smart table name"} value={smartTableName} handleChange={name => setSmartTableName(name)} theme={"small-input"} placeholder={"type smart name"} />
                    }
                    <Buttons onclick={() => { onSmartCreate(window.location.pathname === "/smarts/edit")}} name={`${window.location.pathname === "/smarts/edit" ? "update" : "save"} smart`} icon={<SaveIcon />} theme={'btn'} />
                </div>
            </div>
            <div className="main-content" style={{ display: "flex", gap: "8px" }}>
                {
                    !!tables.filter(t => t.status > 0).length &&
                    <AttachTable
                        label="tables"
                        options={tables.filter(t => t.status > 0).map((table) => ({ name: table.data.tableName, tableId: table.data.tableId, status: table.status }))}
                        handleToggleChange={onTableToggle}
                    />
                }
                {
                    !!(commonKeysFromTables(tables).length) &&
                    <CommonKeys
                        label="common Keys"
                        options={
                            commonKeysFromTables(tables) // get common keys
                                .map(k => ({ keyName: k.keyName, keyValue: k.keyId, status: k.status })) // map keys to options
                        }
                        handleToggleChange={onKeyToggle}
                    />
                }
            </div>
        </>
    )
}
