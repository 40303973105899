import { NavLink } from "react-router-dom";
import "./styles/menu.css";

export default function Menu({ href, name, icon, icon1, isCollapsed }) {
    const classList = ["menu meduim-16"];
    isCollapsed && classList.push("collapse");

    return (
        <NavLink className={classList.join(" ")} to={href}>
            <div className="icon">{icon}</div>
            <div className="icon-active">{icon1}</div>
            {!isCollapsed && name}
        </NavLink>
    );
}
