import { Children } from 'react'
import './styles/action-bar.css'
export default function TopDiv({ children }) {
    return (
        <div className="hxp-comp-action-bar">
            {Children.map(children, child => <>{ child }</>
            )}
        </div>
    )
}
