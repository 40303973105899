import "./styles/new-key.css";

export default function NewKeys({options}) {
 
  return (
    <div>
        {
            options.map((option,index)=>{
                const classList =["key buttonKey"];
                if(option.value === ""){classList.push("emptyBtn")}
                return(
                    <div key={index}>
                        <button className={classList.join(" ")} value={option.value}>{option.name}</button>
                    </div>
                )
            })
        }
    </div>
  )
}
